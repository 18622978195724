import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, Router } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { ClientComponent } from './client/client.component';

import { AdminComponent } from './admin/admin.component';

import { LoginComponent } from './login/login.component';
import { EventOverviewComponent } from './event-overview/event-overview.component';


const routes: Routes = [
  { path: '', component: OverviewComponent },
  { path: 'login', component: LoginComponent },
  { path: 'inquiry', component: ClientComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'dashboard', component: EventOverviewComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  declarations: []
})

export class AppRoutingModule { }
export const routingComponents = [
  OverviewComponent,
  ClientComponent,
  LoginComponent,
  AdminComponent,
  EventOverviewComponent
];
