import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalService } from '../local.service';
import { Router } from '@angular/router';
import { Chart, registerables } from 'chart.js';
import { readdir } from 'fs';
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  rideList: any = [];
  fullList: any = [];
  selTab: any = 0;
  strSearch: any = "";
  modalReason: any = false;
  modalDeny: any = false;
  modalDenySuccess: any = false;
  modalApprove: any = false;
  modalApproveSuccess: any = false;
  modalEdit: any = false;
  idHolder: any = 0;
  reason: any = '';
  catList: any = [];
  sizeList: any = [];
  userData: any = { first_name: '' };
  remarks: any = '';
  constructor(public http: HttpClient, public local: LocalService, public router: Router) {
    this.local.pageName = 'Overview';
    this.local.pageHolder = '';
  }

  ngOnInit() {
    this.pending();

    // this.videoList();
  }



  onEdit(item) {
    this.userData = JSON.parse(JSON.stringify(item));
    this.remarks = this.getLoc(this.userData.remarks)
    // alert(this.userData.first_name)
    this.modalEdit = true;
  }
  hideModal() {
    this.modalEdit = false;
    this.modalApprove = false;
  }

  updateData() {
    // alert(this.getOpt(this.userData.remarks))
    let strQuery = "update tbl_registration set first_name = '" + this.userData.first_name + "',last_name = '" + this.userData.last_name + "',gender='" + this.userData.gender + "',remarks='" + this.remarks + "~" + this.getOpt(this.userData.remarks) + "',mobile='" + this.userData.mobile + "',email='" + this.userData.email + "',size = " + this.userData.size + ", category = " + this.userData.category + " where id = " + this.userData.id;
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      this.getCategory();
      this.hideModal();
    });
  }
  getCategory() {
    const strQuery = "select * from tbl_category ";
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      let res: any = data;
      this.catList = res
      const strQuery2 = "select * from tbl_size ";
      this.http.post(this.local.host + '/get/query/db/', { query: strQuery2 }).subscribe(data => {
        let res: any = data;
        this.sizeList = res
        this.pending()
      })
    })
  }


  getCat(id) {
    let category = this.catList.filter(data => {
      return (data.id == id)
    })
    if (category.length != 0) {
      return category[0].cateogry;
    } else {
      return "";
    }

  }

  getSize(id) {
    let size = this.sizeList.filter(data => {
      return (data.id == id)
    });
    if (size.length != 0) {
      return size[0].size;
    } else {
      return "";
    }
  }

  getLoc(id) {
    if (id != null) {
      let loc = id.split('~');
      if (loc.length != 0) {
        return loc[0];
      } else {
        return "";
      }
    }

  }

  getOpt(id) {
    if (id != null) {
      let loc = id.split('~');
      // alert(JSON.stringify(loc))
      if (loc.length > 1) {
        return (loc[1] == 'true' || loc[1] == 1) ? 'Yes' : 'No';
      } else {
        return "";
      }
    }
  }

  pending() {
    this.http.get(this.local.host + '/redeem').subscribe(data => {
      console.log(data);
      this.rideList = data;
      this.fullList = data;
      // this.rideList = this.fullList.splice(0,10);
    });
  }
  approved() {
    this.selTab = 1;
    let strQuery = 'select *,p.id as \'pid\',p.status as \'ps\' from tbl_payyment as p left join tbl_registration as r on r.id = p.user_id where r.remarks like \'%clark%\' order by  p.id desc';
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      console.log(data);
      this.rideList = data;
      this.fullList = data;
    });
  }

  denied() {
    this.selTab = 2;
    let strQuery = 'select *,p.id as \'pid\',p.status as \'ps\' from tbl_payyment as p left join tbl_registration as r on r.id = p.user_id where r.remarks like \'%cebu%\' order by  p.id desc';
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      console.log(data);
      this.rideList = data;
      this.fullList = data;
    });
  }


  approveEntry(id) {
    this.modalApprove = true;
    this.idHolder = id;
  }
  denyEntry(id) {
    this.modalReason = true;
    this.idHolder = id;
  }
  sendNotif(userDetails, status) {

    if (status == 1) {
      this.http.get(this.local.host + '/send-event-email-approve/' + userDetails.email).subscribe(edata => {
        console.log(edata)
      })
      this.http.get(this.local.host + '/send-sms-approve/' + userDetails.mobile).subscribe(sdata => {
        console.log(sdata)
      })
    } else {
      this.http.get(this.local.host + '/send-event-email-deny/' + userDetails.email + '/' + this.reason).subscribe(edata => {
        this.reason = '';
        console.log(edata)
      })
      this.http.get(this.local.host + '/send-sms-deny/' + userDetails.mobile).subscribe(sdata => {
        console.log(sdata)
      })
      this.modalDeny = false;
      this.modalDenySuccess = true;
    }

  }


  videoList() {
    this.selTab = 0;
    let strQuery = 'SELECT *,ae.id as \'entry_id\' FROM `tbl_special_event_activity_entry` AS ae LEFT JOIN tbl_users AS u ON u.id = ae.user_id where ae.status = 0 and ae.user_id not in (SELECT user_id FROM tbl_special_event_activity_entry where status = 1)'
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      console.log(data);
      this.rideList = data;
      this.fullList = data;
    });
  }

  videoListApprove() {
    this.selTab = 1;
    let strQuery = 'SELECT *,ae.id as \'entry_id\' FROM `tbl_special_event_activity_entry` AS ae LEFT JOIN tbl_users AS u ON u.id = ae.user_id where ae.status = 1'
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      console.log(data);
      this.rideList = data;
      this.fullList = data;
    });
  }

  videoListDeny() {
    this.selTab = 2;
    let strQuery = 'SELECT *,ae.id as \'entry_id\' FROM `tbl_special_event_activity_entry` AS ae LEFT JOIN tbl_users AS u ON u.id = ae.user_id where ae.status = 2'
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      console.log(data);
      this.rideList = data;
      this.fullList = data;
    });
  }


  updateStatus(data, status) {
    let stat = (status == 1) ? 'Approve' : 'Deny';
    // let a = confirm(stat + ' this entry?');
    // if (a) {
    let strQuery = 'UPDATE tbl_payyment set status = ' + status + ' WHERE id = ' + data.pid;
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(info => {
      console.log(data);
      this.modalApprove = false;
      this.modalDeny = false;
      let strQuery = 'UPDATE tbl_registration set status = ' + status + ' WHERE id = ' + data.user_id;
      this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(info => {
        if (status == 1) {
          this.modalApproveSuccess = true;
        } else {
          this.modalDenySuccess = true;
        }
      });



      this.pending();
    });
    // }
  }

  search() {

    let str = this.strSearch.toLowerCase();
    this.rideList = this.fullList.filter(data => {
      if (data.mobile != null) {
        return data.mobile.toLowerCase().includes(str) || data.product.toLowerCase().includes(str) || data.merchant.toLowerCase().includes(str)
      }
    });
  }

  loadRides() {
    this.selTab = 0;
    const strQuery = 'SELECT *,r.name AS \'hashtag\',r.id AS \'rid\',er.status AS \'reg_status\',ee.status AS \'activity_status\' FROM tbl_rider_activities AS r ' +
      'LEFT JOIN tbl_users AS u ON u.id = r.user_id  ' +
      'LEFT JOIN tbl_special_event_registration as er ON er.user_id = u.id ' +
      'LEFT JOIN tbl_special_event_entries as ee ON ee.activity_id = r.id ' +
      // 'WHERE r.distance >= 25000 AND r.ride_type = \'bike\' ';
      'WHERE r.distance >= 25000 AND r.ride_type = \'bike\' AND (r.name like \'%traqsloop%\' OR r.name like \'%traqsvermosa%\') AND u.id IN (SELECT user_id from tbl_special_event_registration where status = 1) AND r.id NOT IN (SELECT activity_id FROM tbl_special_event_entries)';
    // const strQuery = 'SELECT *,r.id as \'rid\' FROM tbl_rider_activities AS r LEFT JOIN tbl_users AS u ON u.id = r.user_id WHERE distance >= 25000 AND ride_type = \'bike\' AND MONTH(r.end_date) = 7';
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      console.log(data);
      this.rideList = data;
      this.fullList = data;
    });
  }
  loadApprove() {
    this.selTab = 1;
    const strQuery = 'SELECT *,r.name AS \'hashtag\',r.id AS \'rid\',er.status AS \'reg_status\',ee.status AS \'activity_status\' FROM tbl_rider_activities AS r ' +
      'LEFT JOIN tbl_users AS u ON u.id = r.user_id  ' +
      'LEFT JOIN tbl_special_event_registration as er ON er.user_id = u.id ' +
      'LEFT JOIN tbl_special_event_entries as ee ON ee.activity_id = r.id ' +
      // 'WHERE r.distance >= 25000 AND r.ride_type = \'bike\' ';
      'WHERE r.distance >= 25000 AND r.ride_type = \'bike\' AND (r.name like \'%traqsloop%\' OR r.name like \'%traqsvermosa%\') AND  r.id  IN (SELECT activity_id FROM tbl_special_event_entries WHERE status = 1)';
    // const strQuery = 'SELECT *,r.id as \'rid\' FROM tbl_rider_activities AS r LEFT JOIN tbl_users AS u ON u.id = r.user_id WHERE distance >= 25000 AND ride_type = \'bike\' AND MONTH(r.end_date) = 7';
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      console.log(data);
      this.rideList = data;
      this.fullList = data;
    });
  }

  loadDecline() {
    this.selTab = 2;
    const strQuery = 'SELECT *,r.name AS \'hashtag\',r.id AS \'rid\',er.status AS \'reg_status\',ee.status AS \'activity_status\' FROM tbl_rider_activities AS r ' +
      'LEFT JOIN tbl_users AS u ON u.id = r.user_id  ' +
      'LEFT JOIN tbl_special_event_registration as er ON er.user_id = u.id ' +
      'LEFT JOIN tbl_special_event_entries as ee ON ee.activity_id = r.id ' +
      // 'WHERE r.distance >= 25000 AND r.ride_type = \'bike\' ';
      'WHERE r.distance >= 25000 AND r.ride_type = \'bike\' AND (r.name like \'%traqsloop%\' OR r.name like \'%traqsvermosa%\') AND  r.id  IN (SELECT activity_id FROM tbl_special_event_entries WHERE status = 0)';
    // const strQuery = 'SELECT *,r.id as \'rid\' FROM tbl_rider_activities AS r LEFT JOIN tbl_users AS u ON u.id = r.user_id WHERE distance >= 25000 AND ride_type = \'bike\' AND MONTH(r.end_date) = 7';
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      console.log(data);
      this.rideList = data;
      this.fullList = data;
    });
  }


  getDuration(d) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    let hDisplay = h > 0 ? h + (h == 1 ? " : " : " : ") : "00 : ";
    let mDisplay = m > 0 ? m + (m == 1 ? " : " : " : ") : "";
    let sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  goTo(item) {
    this.router.navigate(['event-view', item.rid]);
  }

  dlList() {
    let data = [];
    if (this.rideList.length != 0) {
      this.rideList.filter(res => {
        if (res.mobile != null) {
          data.push({
            'USER_ID': res.id,
            'MOBILE': res.mobile,
            'PRODUCT': res.product,
            'MERCHANT': res.merchant,
            'STATUS': (res.status == 0) ? 'Done' : 'Pending',
            'DATE REGISTERED': res.created,
          });
        }

      });
      data.sort((a, b) => a.USER_ID - b.USER_ID)
      this.local.dl('Redemption List', data);
    }
  }
}
