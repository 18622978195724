import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalService } from './local.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'salesforce';
  myDate: any = new Date();
  userInfo: any = {};
  showNav: any = true;
  constructor(public router: Router, public local: LocalService) {


  }
 
  goTo(pageName) {
    this.local.pageHolder = pageName;
    this.router.navigate([pageName]);
  }
  signOut() {
    this.local.local.remove('auth');
    this.local.local.remove('traqs-admin');
    this.local.local.clear();
    this.local.isLogin = false;
    this.router.navigate(['login']);
  }
}
