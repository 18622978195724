import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router } from '@angular/router';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { resolve } from 'path';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class LocalService {
  msgType: any = 1;
  selDist: any = {};
  selectedTrans: any = {};
  pageName: any = 'Overview';
  pageHolder: any = '';

  // host: any = 'http://localhost:4001'; //dev
  // hostAdmin: any = 'https://dev.inventlabs.tech/traqs-api'; //staging
  host: any = 'https://prod-api.redeemly.ph'; //prod
  hostAdmin: any = 'https://api.traqs.ph'; //prod
  // host:any = 'http://localhost:7050'//local
  headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Authorization', '65GhD4A31K');
  headers2 = new HttpHeaders()
    .set('Authorization', '65GhD4A31K');
  // host: any = "http://localhost:7050";

  token: any = '78febdffaca8aa2f4141f3361aa72f2a99de074c';
  domain: any = '';
  isLogin: any = true;
  // httpOptions: any = {
  //   headers: new HttpHeaders({
  //     'Authorization': 'Token 0979e04f99a344557e1e76da2b2b3b21550f4b83'
  //   })
  // };
  httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'undefined'
    })
  };
  lastPage: any = '';
  userDetails: any = {};
  clientDetails: any = {};
  rewardDetails: any = {};
  contentDetails: any = {};
  province: any = [];
  city: any = [];
  barangay: any = [];
  adminInfo: any = {};
  toke: any = '';
  eventId: any = 0;

  constructor(public http: HttpClient, public local: LocalStorageService, public router: Router) {
   

    // this.loadProvince();
  }
  loadProvince() {
    this.http.get('assets/provinces.json').subscribe(data => {
      // //console.log(data)
      const res: any = data;
      this.province = res.RECORDS;
      //console.log(this.province[0])
    });
    this.http.get('assets/cities.json').subscribe(data => {
      // //console.log(data)
      const res: any = data;
      this.city = res.RECORDS;
      //console.log(this.city[0])
    });
    this.http.get('assets/barangays.json').subscribe(data => {
      // //console.log(data)
      const res: any = data;
      this.barangay = res.RECORDS;
      //console.log(this.barangay[0])
    });
  }

  dl(title, data) {
    if (data.length != 0) {
      const header = Object.keys(data[0]);
      const content = Object.values(data);
      // this.regionList = Object.keys(data);
      //////console.log("header", header);
      //////console.log("content", content);
      this.dlCSV(header, title + '(' + new Date() + ')', content);
    } else {
      alert('No data to be downloaded');
    }
  }
  dlh(title, data,head) {
    if (data.length != 0) {
      const header = Object.keys(head[0]);
      const content = Object.values(data);
      // this.regionList = Object.keys(data);
      //////console.log("header", header);
      //////console.log("content", content);
      this.dlCSV(header, title + '(' + new Date() + ')', content);
    } else {
      alert('No data to be downloaded');
    }
  }
  myBrowser() {

    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1) {

      return 'Opera';

    } else if (navigator.userAgent.indexOf('Chrome') != -1) {

      return 'Chrome';

    } else if (navigator.userAgent.indexOf('Safari') != -1) {

      return 'Safari';

    } else if (navigator.userAgent.indexOf('Firefox') != -1) {

      return 'Firefox';

    } else if ((navigator.userAgent.indexOf('MSIE') != -1)) {

      return 'IE';

    } else {

      return 'this browser';

    }

  }


  goTo(pageName) {
    this.router.navigate([pageName]);
  }

  dlCSV(header, name, data) {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: header
    };
    // let data = [
    //   {
    //     name: "Test 1",
    //     age: 13,
    //     average: 8.2,
    //     approved: true,
    //     description: "using 'Content here, content here' "
    //   },
    //   {
    //     name: 'Test 2',
    //     age: 11,
    //     average: 8.2,
    //     approved: true,
    //     description: "using 'Content here, content here' "
    //   },
    //   {
    //     name: 'Test 4',
    //     age: 10,
    //     average: 8.2,
    //     approved: true,
    //     description: "using 'Content here, content here' "
    //   },
    // ];

    new Angular5Csv(data, name, options);
  }



  public exportAsExcelFile(json: any[], excelFileName: string): void {
    // let wb = { SheetNames: [], Sheets: {} };
    ////console.log(json)
    const sum: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[0].sum);
    const raw: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[1].raw);
    const bu: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[2].bu);
    const cp: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[3].cp);
    const trg: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[4].trg);
    const ar: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[5].ar);
    const ag: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[6].ag);
    const workbook: XLSX.WorkBook = {
      Sheets: {
        'Summary Filters': sum,
        'Raw Data': raw,
        'BU': bu,
        'Chanel Performance': cp,
        'Top Retailer Grower': trg,
        'Active Retailer': ar,
        'Active Grower': ag
      }, SheetNames: ['Summary Filters', 'Raw Data', 'BU', 'Chanel Performance', 'Top Retailer Grower', 'Active Retailer', 'Active Grower']
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public consolidatedRetailer(json: any, excelFileName: string): void {
    // let wb = { SheetNames: [], Sheets: {} };
    ////console.log(json)
    const rp: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json.rp);
    const rs: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json.rs);
    const rr: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json.rr);

    const workbook: XLSX.WorkBook = {
      Sheets: {
        'Retailer Profile': rp,
        'Retailer Sales Transactions': rs,
        'Retailer Rewards': rr
      }, SheetNames: ['Retailer Profile', 'Retailer Sales Transactions', 'Retailer Rewards']
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public consolidatedGrower(json: any, excelFileName: string): void {
    // let wb = { SheetNames: [], Sheets: {} };
    ////console.log(json)
    const gp: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json.gp);
    const gs: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json.gs);
    const gr: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json.gr);

    const workbook: XLSX.WorkBook = {
      Sheets: {
        'Grower Profile': gp,
        'Grower Sales Transactions': gs,
        'Grower Rewards': gr
      }, SheetNames: ['Grower Profile', 'Grower Sales Transactions', 'Grower Rewards']
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public onePage(json: any, excelFileName: string): void {
    // let wb = { SheetNames: [], Sheets: {} };
    ////console.log(json)
    const data: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: {
        'data': data
      }, SheetNames: ['data']
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
    FileSaver.saveAs(data, fileName + '.xlsx');
  }

}
