import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalService } from '../local.service';
import { Router } from '@angular/router';
import { Chart, registerables } from 'chart.js';
import { ActivatedRoute } from '@angular/router';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import * as S3 from 'aws-sdk/clients/s3';
@Component({
  selector: 'app-event-overview',
  templateUrl: './event-overview.component.html',
  styleUrls: ['./event-overview.component.css']
})
export class EventOverviewComponent implements OnInit {

  userList: any = [];
  joinedList: any = [];
  finishedChallenge: any = [];
  quionsList: any = [];
  winnerList: any = [];
  totalEarned: any = 0;
  totalRedeemed: any = 0;
  totalChallenge: any = 0;
  totalJoined: any = 0;
  qoinsChart: any;
  challengeChart: any;
  activities: any = 0;
  referreList: any = 0;
  referredList: any = 0;
  clientList: any = [];
  clientPoints: any = 0;
  clientPointsUsed: any = 0
  female: any = 0;
  male: any = 0;
  params: any;
  photo: any;
  photoFile: any;
  photoUrl: any;
  modalConfirm: any = false;
  modalLoading: any = false;
  modalSuccess: any = false;
  modalMsg: any = '';
  bannerList: any = [];
  sponsorList: any = [];
  totalCebu: any = [];
  totalClark: any = [];
  totalPayment: any = [];
  totalCebuPayment: any = [];
  totalClarkPayment: any = [];
  totalPaid: any = [];
  totalCebuPaid: any = [];
  totalClarkPaid: any = [];
  totalPaidv: any = [];
  totalCebuPaidv: any = [];
  totalClarkPaidv: any = [];
  constructor(public http: HttpClient, public local: LocalService, public router: Router, public route: ActivatedRoute) {
    this.local.pageName = 'Overview';
    this.local.pageHolder = '';
    // this.route.params.subscribe(params => this.params = params);
  }

  ngOnInit() {

    // this.loadUsers();
    this.loadUsers();

    // this.loadSpecialEventBanners();

  }

  loadSpecialEventBanners() {
    let strQuery = "SELECT * FROM tbl_special_event_photo where type in ('thumbnail','banner','mobile','badge') AND event_id = " + this.params.id;
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      this.bannerList = data;
      console.log('works a like pro', data)
    });
    let strQuerySponsor = "SELECT * FROM tbl_special_event_photo where type in ('sponsor') AND event_id = " + this.params.id;
    this.http.post(this.local.host + '/get/query/db/', { query: strQuerySponsor }).subscribe(data => {
      this.sponsorList = data;
      console.log('works a like pro', data)
    });
  }
  // loadSpecialEventDetails() {
  //   let strQuery = "SELECT * FROM tbl_special_event_entries where activity_id = " + this.params.id + " group by user_id"
  //   this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
  //     this.userList = data;
  //     console.log(data);
  //     this.formatGender()
  //   });
  // }


  formatQoinsChart() {

    this.qoinsChart = new Chart('qoins', {
      type: 'doughnut',
      data: {
        labels: ['Reedemed', 'Available'],
        datasets: [{
          label: 'Qoins chart',
          data: [this.totalRedeemed, (this.totalEarned - this.totalRedeemed)],
          backgroundColor: [
            'rgb(255, 199, 108,1)',
            'rgb(12, 171, 106,1)'
          ],
          hoverOffset: 4
        }]
      }
    });
  }

  formatChallengeChart() {
    this.challengeChart = new Chart('challenge', {
      type: 'doughnut',
      data: {
        labels: ['Active', 'Joined'],
        datasets: [{
          label: 'Challenge chart',
          data: [this.totalChallenge, this.totalJoined],
          backgroundColor: [
            'rgb(14, 91, 97,1)',
            'rgb(12, 171, 106,1)'
          ],
          hoverOffset: 4
        }]
      }
    });
  }

  loadUsers() {
    const strQuery = "select r.id as 'USER ID',r.first_name as 'FIRST NAME',r.last_name as 'LAST NAME',DATE_FORMAT(r.birthdate, '%m-%d-%Y') as 'BIRTHDATE',r.gender as 'GENDER',r.mobile as 'MOBILE',r.email as 'EMAIL',r.address as 'ADDRESS',c.cateogry as 'CATEGORY', s.size as 'SIZE',r.remarks as 'LOCATION',p.type as 'PAYMENT TYPE',p.reference as 'REFERENCE',CASE WHEN p.status = 1 THEN 'PAID' ELSE 'PENDING' END as 'PAYMENT STATUS', DATE_FORMAT(r.created, '%m-%d-%Y %h:%m:%p') as 'DATE REGISTERED' from tbl_registration as r left join tbl_payyment as p on p.user_id = r.id left join tbl_category as c on c.id = r.category left join tbl_size as s on s.id = r.size";
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      this.userList = data;
      this.totalCebu = this.userList.filter(data => {

        data.LOCATION = data.LOCATION.split('~')[0].toUpperCase();
        data.OPT_IN = 0;
        console.log(data.LOCATION.split('~').length)
        if (data.LOCATION.split('~').length > 1) {
          data.OPT_IN = data.LOCATION.split('~')[1];
        }

        return data.LOCATION == 'CEBU';
      });
      this.totalClark = this.userList.filter(data => {
        data.LOCATION = data.LOCATION.split('~')[0].toUpperCase();
        data.OPT_IN = 0;
        if (data.LOCATION.split('~').length > 1) {
          data.OPT_IN = data.LOCATION.split('~')[1];
        }
        return data.LOCATION == 'CLARK';
      });
      this.loadPaid();

    });
  }

  loadPayment() {
    const strQuery = "select r.first_name as 'FIRST NAME',r.last_name as 'LAST NAME',DATE_FORMAT(r.birthdate, '%m-%d-%Y') as 'BIRTHDATE',r.gender as 'GENDER',r.mobile as 'MOBILE',r.email as 'EMAIL',r.address as 'ADDRESS',c.cateogry as 'CATEGORY', s.size as 'SIZE',r.remarks as 'LOCATION','PENDING' as 'PAYMENT STATUS',DATE_FORMAT(r.created, '%m-%d-%Y') as 'DATE REGISTERED' from tbl_registration as r left join tbl_category as c on c.id = r.category left join tbl_size as s on s.id = r.size where r.id not in (select user_id from tbl_payyment where status = 1)";
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      console.log('works like a charm', data)
      this.totalPayment = data;
      this.totalCebuPayment = this.totalPayment.filter(data => {
        data.LOCATION = data.LOCATION.split('~')[0].toUpperCase();
        return data.LOCATION.includes('CEBU');
      });
      this.totalClarkPayment = this.totalPayment.filter(data => {
        data.LOCATION = data.LOCATION.split('~')[0].toUpperCase();
        return data.LOCATION.includes('CLARK');
      });
    });
  }

  loadPaidVoucher() {
    const strQuery = "select r.first_name as 'FIRST NAME',r.last_name as 'LAST NAME',DATE_FORMAT(r.birthdate, '%m-%d-%Y') as 'BIRTHDATE',r.gender as 'GENDER',r.mobile as 'MOBILE',r.email as 'EMAIL',r.address as 'ADDRESS',c.cateogry as 'CATEGORY', s.size as 'SIZE',r.remarks as 'LOCATION',p.type as 'PAYMENT TYPE',p.reference as 'REFERENCE',CASE WHEN p.status = 1 THEN 'PAID' ELSE 'PENDING' END as 'PAYMENT STATUS',DATE_FORMAT(r.created, '%m-%d-%Y') as 'DATE REGISTERED',p.reference from tbl_registration as r left join tbl_payyment as p on p.user_id = r.id left join tbl_category as c on c.id = r.category left join tbl_size as s on s.id = r.size where p.status = 1 and p.type = 'voucher'";
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      this.totalPaidv = data;
      console.log('works like a charm', data)
      this.totalCebuPaidv = this.totalPaidv.filter(data => {
        data.LOCATION = data.LOCATION.split('~')[0].toUpperCase();
        data.OPT_IN = 0;
        if (data.LOCATION.split('~').length > 1) {
          data.OPT_IN = data.LOCATION.split('~')[1];
        }
        return data.LOCATION.includes('CEBU');
      });
      this.totalClarkPaidv = this.totalPaidv.filter(data => {
        data.LOCATION = data.LOCATION.split('~')[0].toUpperCase();
        data.OPT_IN = 0;
        if (data.LOCATION.split('~').length > 1) {
          data.OPT_IN = data.LOCATION.split('~')[1];
        }
        return data.LOCATION.includes('CLARK');
      });
    });
  }

  loadPaid() {
    const strQuery = "select r.first_name as 'FIRST NAME',r.last_name as 'LAST NAME',DATE_FORMAT(r.birthdate, '%m-%d-%Y') as 'BIRTHDATE',r.gender as 'GENDER',r.mobile as 'MOBILE',r.email as 'EMAIL',r.address as 'ADDRESS',c.cateogry as 'CATEGORY', s.size as 'SIZE',r.remarks as 'LOCATION',p.type as 'PAYMENT_TYPE',p.reference as 'REFERENCE',CASE WHEN p.status = 1 THEN 'PAID' ELSE 'PENDING' END as 'PAYMENT STATUS',DATE_FORMAT(r.created, '%m-%d-%Y') as 'DATE REGISTERED',p.reference, p.amount as 'AMOUNT' from tbl_registration as r left join tbl_payyment as p on p.user_id = r.id left join tbl_category as c on c.id = r.category left join tbl_size as s on s.id = r.size where p.status = 1";
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      let ret: any = data;
      this.totalPaid = ret.filter(rec => {
        return rec.PAYMENT_TYPE.includes('Online')
      });;
      this.totalPaidv = ret.filter(rec => {
        return rec.PAYMENT_TYPE.includes('voucher')
      });
      this.totalCebuPaid = this.totalPaid.filter(data => {
        data.LOCATION = data.LOCATION.split('~')[0].toUpperCase();
        data.OPT_IN = 0;
        if (data.LOCATION.split('~').length > 1) {
          data.OPT_IN = data.LOCATION.split('~')[1];
        }
        return data.LOCATION.includes('CEBU');
      });
      this.totalClarkPaid = this.totalPaid.filter(data => {
        data.LOCATION = data.LOCATION.split('~')[0].toUpperCase();
        data.OPT_IN = 0;
        if (data.LOCATION.split('~').length > 1) {
          data.OPT_IN = data.LOCATION.split('~')[1];
        }
        return data.LOCATION.includes('CLARK');
      });
      // this.totalCebuPaidv = this.totalPaidv.filter(data => {
      //   data.LOCATION = data.LOCATION.split('~')[0].toUpperCase();
      //   data.OPT_IN = 0;
      //   if (data.LOCATION.split('~').length > 1) {
      //     data.OPT_IN = data.LOCATION.split('~')[1];
      //   }
      //   return data.LOCATION.includes('CEBU');
      // });
      this.totalCebuPaidv = this.totalPaid.filter(data => {
        data.LOCATION = data.LOCATION.split('~')[0].toUpperCase();
        data.OPT_IN = 0;
        if (data.LOCATION.split('~').length > 1) {
          data.OPT_IN = data.LOCATION.split('~')[1];
        }
        if (data.LOCATION.includes('CLARK')) {
          return data.AMOUNT == 1500 || data.AMOUNT == 150
        }
      });
      this.totalClarkPaidv = this.totalPaidv.filter(data => {
        data.LOCATION = data.LOCATION.split('~')[0].toUpperCase();
        data.OPT_IN = 0;
        if (data.LOCATION.split('~').length > 1) {
          data.OPT_IN = data.LOCATION.split('~')[1];
        }
        return data.LOCATION.includes('CLARK');
      });
      this.loadPayment();
    });
  }

  formatGender() {
    this.male = this.userList.filter(data => {
      return data.gender === 0;
    });
    this.female = this.userList.filter(data => {
      return data.gender === 1;
    });

  }

  goTo(data) {
    this.local.userDetails = JSON.parse(JSON.stringify(data));
    this.router.navigate(['user-view', data.id]);
  }

  goToValidate() {
    this.router.navigate(['event-new', this.params.id]);
  }

  dlList() {
    this.local.dl('Reggistered List', this.userList);
  }

  selectFile(event, i) {
    //0 = photo,1 = regular, 2 = premium
    this.photo = event.target.files;
    this.upload(i);
  }
  upload(i) {
    this.photoFile = this.photo.item(0);
    this.modalConfirm = true;
    this.uploadfile(i);
  }

  uploadfile(i) {

    this.modalConfirm = false;
    this.modalLoading = true;
    const self: any = this;

    const bucket = new S3(
      {
        accessKeyId: 'AKIA4B46NTF6BNEB7SM6',
        secretAccessKey: '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',
        region: 'ap-southeast-1'
      }
    );
    const file: any = self.photoFile;
    console.log(file);
    const params = {
      Bucket: 'traqs-images',
      Key: 'Traqs-' + Math.round(new Date().getTime() / 1000) + '.' + file.name.split('.')[1],
      Body: file
    };

    bucket.upload(params, function (err, data) {
      if (err) {
        //console.log(this.selectedSG);
        console.log('There was an error uploading your file: ', err);
        self.photoUrl = '';
        self.update(i);
        return false;
      } else {
        //console.log(this.selectedSG);
        console.log('Successfully uploaded file.', data);
        const imgPath = data;
        self.photoUrl = imgPath.Location;
        self.update(i);
        return true;
      }
    });
  }

  update(i) {
    const url = this.photoUrl;
    const type = (i == 0) ? 'thumbnail' : (i == 1) ? 'banner' : (i == 2) ? 'mobile' : 'badge';
    const bannerInfo = this.bannerList.filter(data => {
      return data.type === type;
    });
    if (bannerInfo.length !== 0) {
      const strQuery = 'UPDATE tbl_special_event_photo SET photo = \'' + url + '\' where id = ' + bannerInfo[0].id;
      this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
        // i++;
        // if (i <= 2) {
        //   this.savePhoto(i, id);
        // } else {
        this.modalMsg = type + ' updated.';
        this.modalLoading = false;
        this.modalSuccess = true;

        // }

      });
    } else {
      this.savePhoto(i)
    }

  }
  savePhoto(i) {
    const url = this.photoUrl;
    // const type = 'sponsor';
    const type = (i == 0) ? 'thumbnail' : (i == 1) ? 'banner' : (i == 2) ? 'mobile' : 'badge';
    const strQuery = 'INSERT INTO `tbl_special_event_photo` ( `event_id`, `type`, `photo`, `created`, `remarks`) VALUES ( \'' + this.params.id + '\', \'' + type + '\', \'' + url + '\', CURRENT_TIMESTAMP, \'\')';
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      // i++;
      // if (i <= 2) {
      //   this.savePhoto(i, id);
      // } else {
      this.modalMsg = type + ' added.';
      this.modalLoading = false;
      this.modalSuccess = true;
      // }

    });
  }

  selectFileSponsor(event) {
    //0 = photo,1 = regular, 2 = premium
    this.photo = event.target.files;
    this.uploadSponsor();
  }
  uploadSponsor() {
    this.photoFile = this.photo.item(0);
    this.modalConfirm = true;
    this.uploadfileSponsor();
  }

  uploadfileSponsor() {

    this.modalConfirm = false;
    this.modalLoading = true;
    const self: any = this;

    const bucket = new S3(
      {
        accessKeyId: 'AKIA4B46NTF6BNEB7SM6',
        secretAccessKey: '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',
        region: 'ap-southeast-1'
      }
    );
    const file: any = self.photoFile;
    console.log(file);
    const params = {
      Bucket: 'traqs-images',
      Key: 'Traqs-' + Math.round(new Date().getTime() / 1000) + '.' + file.name.split('.')[1],
      Body: file
    };

    bucket.upload(params, function (err, data) {
      if (err) {
        //console.log(this.selectedSG);
        console.log('There was an error uploading your file: ', err);
        self.photoUrl = '';
        self.saveSponsor();
        return false;
      } else {
        //console.log(this.selectedSG);
        console.log('Successfully uploaded file.', data);
        const imgPath = data;
        self.photoUrl = imgPath.Location;
        self.saveSponsor();
        return true;
      }
    });
  }


  saveSponsor() {
    const url = this.photoUrl;
    const type = 'sponsor';
    const strQuery = 'INSERT INTO `tbl_special_event_photo` ( `event_id`, `type`, `photo`, `created`, `remarks`) VALUES ( \'' + this.params.id + '\', \'' + type + '\', \'' + url + '\', CURRENT_TIMESTAMP, \'\')';
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      this.modalMsg = 'Sponsor added.';
      this.modalLoading = false;
      this.modalSuccess = true;
    });
  }


  removePhoto(id) {
    const isDelete = confirm('Are you sure you want to delete this sponsor ? ');
    if (isDelete) {
      this.modalLoading = true;
      const strQuery = 'delete from `tbl_special_event_photo` where id = ' + id;
      this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
        this.modalMsg = 'Sponsor deleted';
        this.modalLoading = false;
        this.modalSuccess = true;
      });
    }
  }

  dlUser(name, data) {
    this.local.dl(name, data);
  }
}

