import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalService } from '../local.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  modalSuccess: any = false;
  modalConfirm: any = false;
  modalLoading: any = false;
  modalEdit: any = false;
  adminData: any = {};
  adminList: any = [];
  constructor(public http: HttpClient, public local: LocalService, public router: Router) {
    this.local.pageName = 'Admin Management';
    this.local.pageHolder = 'admin';
  }

  ngOnInit() {
    this.loadAdmin();
  }

  hideModal() {
    this.modalConfirm = false;
    this.modalSuccess = false;
    this.modalEdit = false;
  }

  submit() {
    this.hideModal();
    // this.modalLoading = true;
    // const data = new FormData();
    // data.append('name', this.adminData.name);
    // data.append('position', '');
    // data.append('email', this.adminData.email);
    // data.append('password', this.adminData.password);
    // data.append('user_level', this.adminData.user_level);
    // data.append('created_by', this.local.adminInfo.id);
    // this.http.post(this.local.hostAdmin + '/admin/create', data).subscribe(data => {
    //   this.modalLoading = false;
    //   this.modalSuccess = true;
    //   this.loadAdmin();
    // });
    this.modalLoading = true;
    let strQuery = "INSERT INTO user (name,email,username,password) values ('" + this.adminData.name + "~0','" + this.adminData.user_level + "','" + this.adminData.email + "','" + this.adminData.passwor + "')";
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      console.log(data);
      this.modalLoading = false;
      this.modalSuccess = true;
      this.loadAdmin();
    });
  }

  loadAdmin() {
    let strQuery = "select * from user"
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      const result: any = data;
      this.adminList = [];
      result.filter(data=>{
        data.name = data.name.split('~')[0];
        this.adminList.push(data);
      })
      // this.adminList = result;
    });
    // const httpOptions: any = {
    //   headers: new HttpHeaders({
    //     'Authorization': this.local.local.get('auth')
    //   })
    // };
    // this.http.get(this.local.hostAdmin + '/admin/list', httpOptions).subscribe(data => {
    //   const result: any = data;
    //   this.adminList = result.dataObject;
    //   console.log(result);
    // });

  }
}
