import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalService } from '../local.service';
import { Router } from '@angular/router';
import * as S3 from 'aws-sdk/clients/s3';
const Hashids = require('hashids/cjs');
import * as bcrypt from 'bcryptjs';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  clientList: any = [];
  clientName: any = '';
  icon: any = '';
  background: any = '';
  merchant: any = '';
  modalSuccess: any = false;
  modalConfirm: any = false;
  modalLoading: any = false;
  modalEdit: any = false;
  logo: any = '';
  photo: any = '';
  store: any = '';
  storeFile: any = '';
  logoFile: any = '';
  photoFile: any = '';
  email: any = '';
  mobile_num: any = '';
  owner_fname: any = '';
  owner_lname: any = '';
  password: any = '';
  province: any = '';
  city: any = '';
  barangay: any = '';
  loc_latitude: any = '';
  loc_longtitude: any = '';
  address: any = '';
  cityList: any = [];
  barangayList: any = [];
  constructor(public http: HttpClient, public local: LocalService, public router: Router) {
    this.local.pageName = 'Inquiry';
    this.local.pageHolder = 'inquiry';
    console.log(this.generateCode());
  }

  ngOnInit() {
    // console.log(this.hashID('icanseeyou'));
    // this.addPoints();
    // this.addPoints();
    this.loadClient();
  }
  getCity(prov) {
    const province = this.local.province.filter(data => {
      return data.provDesc == prov;
    });
    this.cityList = this.local.city.filter(data => {
      return data.provCode == province[0].provCode;
    });
    this.city = this.cityList[0].citymunDesc;
  }

  getBarangay(city) {
    const province = this.local.city.filter(data => {
      return data.citymunDesc == city;
    });
    this.barangayList = this.local.barangay.filter(data => {
      return data.citymunCode == province[0].citymunCode;
    });
    this.barangay = this.barangayList[0].brgyDesc;
  }

  openForm() {
    this.modalEdit = true;
  }
  showModal() {
    if (this.photoFile != '' && this.logoFile != '' && this.clientName != '') {
      this.modalConfirm = true;
    } else {
      alert('Please fillup required fields');
    }

  }
  hideModal() {
    this.modalConfirm = false;
    this.modalSuccess = false;
    this.modalEdit = false;
  }
  dlList() {
    this.local.dl('Inquiry List', this.clientList);
  }
  addClient() {

    const date = new Date();
    const pass = this.generateCode();
    const strQuery = 'INSERT INTO `tbl_clients`( referral_id,client_logo,merchant_store_photo,loc_latitude,loc_longtitude,address,province,city,barangay,merchant_name,role_id,`name`, `icon`, `background_photo`, `date_created`, `date_updated`,owner_fname,owner_lname,email,mobile_num,password,status) VALUES ' +
      '(\'' + this.generateCode() + '\',\'' + this.icon + '\',\'' + this.merchant.split('\'').join('\\\'') + '\',\'' + this.loc_latitude + '\',\'' + this.loc_longtitude + '\',\'' + this.address + '\',\'' + this.province.split('\'').join('\\\'') + '\',\'' + this.city.split('\'').join('\\\'') + '\',\'' + this.barangay.split('\'').join('\\\'') + '\',\'' + this.clientName.split('\'').join('\\\'') + '\',0,\'' + this.clientName.split('\'').join('\\\'') + '\',\'' + this.icon + '\',\'' + this.background + '\',\'' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + '\',\'' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + '\',\'' + this.owner_fname.split('\'').join('\\\'') + '\',\'' + this.owner_lname.split('\'').join('\\\'') + '\',\'' + this.email + '\',\'' + this.mobile_num + '\',\'' + this.hashID(pass) + '\',0)';

    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      // this.clientList = data;
      console.log(data);
     
      this.http.get(this.local.host + '/send-email/' + pass + '/' + this.email + '/' + this.clientName).subscribe(data => {
        this.addPoints();
        this.clear();
        console.log(data);
        this.modalLoading = false;
        this.modalSuccess = true;
        this.loadClient();
      }, error => {
        this.addPoints();
        this.clear();
        console.log(error);
        this.modalLoading = false;
        this.modalSuccess = true;
        this.loadClient();
      });



    });
  }

  addPoints() {
    this.http.post(this.local.host + '/get/query/db/', { query: 'select * from tbl_clients order by id desc' }).subscribe(data => {
      const res: any = data;
      const httpOptions: any = {
        headers: new HttpHeaders({
          'Authorization': this.local.local.get('auth')
        })
      };
      const points: any = [
        {
          'user_id': res[0].id,
          'points': 500
        }
      ];

      this.http.post(this.local.hostAdmin + '/topup/earned?user_type=client', points, httpOptions).subscribe(data => {
        console.log(data);


      });
    });

  }
  generateCode() {
    let result = '';
    const characters = 'ABCDEFGHJKMNPQRSTUVWXYZ23456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 7; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }

    return result;
  }
  clear() {
    this.clientName = '';
    this.icon = '';
    this.background = '';
    this.owner_fname = '';
    this.owner_lname = '';
    this.email = '';
    this.mobile_num = '';

    this.address = '';
    this.province = '';
    this.city = '';
    this.barangay = '';
    this.loc_latitude = '';
    this.loc_longtitude = '';
  }
  hashID(password) {
    const salt = bcrypt.genSaltSync(10);
    return bcrypt.hashSync(password, salt);
  }

  loadClient() {
    const strQuery = 'select * from contact order by id desc';
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      this.clientList = data;
      console.log(data);
    });
  }

  goTo(data) {
    this.local.clientDetails = JSON.parse(JSON.stringify(data));
    this.router.navigate(['client-view', data.id]);
  }


  selectFile(event, i) {
    //0 = photo,1 = regular, 2 = premium
    if (i == 0) {
      this.logo = event.target.files;
    } else if (i == 1) {
      this.photo = event.target.files;
    } else if (i == 2) {
      this.store = event.target.files;
    }
    this.upload(i);
  }

  upload(i) {
    if (i == 0) {
      this.logoFile = this.logo.item(0);
    } else if (i == 1) {
      this.photoFile = this.photo.item(0);
    } else if (i == 2) {
      this.storeFile = this.store.item(0);
    }

  }


  uploadfile(i) {
    console.log(i);
    this.modalConfirm = false;
    this.modalLoading = true;
    this.modalEdit = false;
    const self: any = this;
    const bucket = new S3(
      {
        accessKeyId: 'AKIA4B46NTF6BNEB7SM6',
        secretAccessKey: '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',
        region: 'ap-southeast-1'
      }
    );

    const file: any = (i == 0) ? self.logoFile : self.photoFile;
    console.log(file);
    const params = {
      Bucket: 'traqs-images',
      Key: 'Traqs-' + Math.round(new Date().getTime() / 1000) + '.' + file.name.split('.')[1],
      Body: file
    };

    bucket.upload(params, function (err, data) {
      if (err) {
        //console.log(this.selectedSG);
        console.log('There was an error uploading your file: ', err);

        if (i == 0) {
          self.icon = '';
        } else if (i == 1) {
          self.background = '';
        } else if (i == 2) {
          self.merchant = '';
        }
        i++;
        if (i <= 1) {
          self.uploadfile(i);
        } else {
          self.addClient();
        }
        return false;
      } else {
        //console.log(this.selectedSG);
        console.log('Successfully uploaded file.', data);
        const imgPath = data;

        if (i == 0) {
          self.icon = imgPath.Location;
        } else if (i == 1) {
          self.background = imgPath.Location;
        } else if (i == 2) {
          self.merchant = imgPath.Location;
        }
        i++;
        //console.log(this.selectedSG)
        if (i <= 2) {
          console.log(i);
          self.uploadfile(i);
        } else {
          self.addClient();
        }
        return true;
      }


    });


  }
}
