import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalService } from '../local.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email: any = '';
  password: any = '';
  password2: any = '';
  otp: any = '';
  type: any = 0; //0 = login, 1 = verify, 2 = change password
  applyType: any = 0; //0 = login, 1 = verify, 2 = change password
  modalMsg: any = '';
  modalHeader: any = '';
  modalAlert: any = false;
  token: any = '';
  psView: any = false;
  psView2: any = false;
  user: any;
  constructor(public http: HttpClient, public local: LocalService, public router: Router) {

    if (this.local.local.get('auth') != null) {
      this.local.isLogin = true;
      this.router.navigate(['']);
    } else {
      this.local.isLogin = false;
    }
  }
  ngOnInit() {
  }
  viewPS(i) {
    if (i == 0) {
      this.psView = (this.psView) ? false : true;
    } else if (i == 1) {
      this.psView2 = (this.psView2) ? false : true;
    }
  }
  hideModal() {
    this.modalAlert = false;
    // this.type = type;
    this.password2 = '';
    this.password = '';
    this.psView = false;
    this.psView2 = false;
    this.email = '';
    if (this.local.local.get('traqs-admin') != null) {
      // this.local.isLogin = true;
      // this.router.navigate([""]);
      location.reload();
    }
  }

  showModal(res) {
    this.modalHeader = res.title;
    this.modalMsg = res.message;
    this.modalAlert = true;

  }

  login() {
    // const creds = new FormData();
    // creds.append('email', this.email);
    // creds.append('password', this.password);

    // this.http.post(this.local.hostAdmin + '/admin/login', creds).subscribe(data => {
    //   console.log(data);
    //   const res: any = data;
    //   if (res.code == 107) {
    //     this.applyType = 1;
    //     this.showModal(res);
    //     this.sendOTP();
    //   } else if (res.code == 100) {
    //     this.getProfile(res);
    //     // this.modalAlert = true;
    //     // this.showModal(res);
    //     // this.local.local.set("auth", res.dataObject)
    //   } else if (res.code == 105) {

    //     this.showModal(res);
    //   }

    // });

    let strQuery = "select * from user where username = '" + this.email + "' && password = '" + this.password + "'";
    this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {
      console.log(data);
      let res: any = data;
      if (res.length != 0) {
        this.user = res[0];
        if (res[0].name.split('~').length > 1) {
          this.showModal({
            title: 'New User Login',
            message: 'Please set your new password'
          });
          this.type = 2
        } else {
          this.local.local.set('auth', res);
          this.local.local.set('traqs-admin', JSON.stringify(res));

          this.hideModal();
        }

      } else {
        this.modalAlert = true;
        this.modalHeader = "Login Error";
        this.modalMsg = "Invalid Credentials";
      }

    });
  }

  getProfile(res) {
    const httpOptions: any = {
      headers: new HttpHeaders({
        'Authorization': res.dataObject
      })
    };
    this.http.get(this.local.hostAdmin + '/admin/me', httpOptions).subscribe(data => {
      const result: any = data;
      if (result.code == 100) {
        this.local.local.set('auth', res.dataObject);
        if (result.dataObject.is_new == 1) {
          this.token = res.dataObject;
          this.email = '';
          this.password = '';
          this.password2 = '';
          this.applyType = 2;
          res.title = 'New Login';
          res.message = 'Please set password';
          this.showModal(res);
        } else {
          this.local.local.set('traqs-admin', JSON.stringify(result.dataObject));
          this.showModal(res);
        }
      }

    });

  }


  sendOTP() {
    const creds = new FormData();
    creds.append('email', this.email);
    this.http.post(this.local.hostAdmin + '/admin/verification/send', creds).subscribe(data => {
      console.log(data);
      const res: any = data;
      if (res.code != 100) {
        this.showModal(res);

      }
    });
  }


  verify() {
    const creds = new FormData();
    creds.append('email', this.email);
    creds.append('otp_code', this.otp);
    this.http.post(this.local.hostAdmin + '/admin/verify', creds).subscribe(data => {
      console.log(data);
      const res: any = data;
      if (res.code == 100) {
        this.applyType = 0;
        this.showModal(res);
      }
    });
  }

  update() {
    if (this.password == this.password2) {
      let strQuery = "update user set password = '" + this.password + "', name = '" + this.user.name.split('~')[0] + "' where id = " + this.user.id;
      this.http.post(this.local.host + '/get/query/db/', { query: strQuery }).subscribe(data => {

        this.showModal({
          title: 'Change Password',
          message: 'Please Login with your new password'
        });
        this.type = 0;
      });
      // const httpOptions: any = {
      //   headers: new HttpHeaders({
      //     'Authorization': this.token
      //   })
      // };
      // const creds = new FormData();
      // creds.append('new_password', this.password);
      // this.http.post(this.local.hostAdmin + '/admin/password-update', creds, httpOptions).subscribe(data => {
      //   console.log(data);
      //   const res: any = data;
      //   if (res.code == 100) {
      //     this.applyType = 0;
      //     this.showModal(res);
      //   } else {
      //     this.showModal(res);
      //   }
      // });
    } else {
      const res: any = {
        title: 'Create Password',
        message: 'Password doesn\'t match'
      };
      this.showModal(res);
    }

  }
}
